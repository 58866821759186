import { Controller } from "@hotwired/stimulus"
import Quill from "quill"

export default class extends Controller {

  static targets = [ "modal", "content", "flash", "gray", "quill", "note" ]

  connect() {
    this.flashTarget.hidden = true;

    if(!Quill.find(this.quillTarget)) {
      let quill = new Quill(this.quillTarget, {
        theme: 'snow'
      });
      let note = this.noteTarget;
      quill.on('text-change', function(delta, oldDelta, source) {
        note.value = quill.container.firstChild.innerHTML;
      });
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // validate modal on form submission
  // action: "turbo:submit-start->turbo-modal#submitStart"
  submitStart(e) {
    let msg = this.validate()
    if(msg.length > 0) {
      e.detail.formSubmission.stop()
      this.flashTarget.innerHTML = "<div class='p-2'>" + msg.join('<br />') + "</div>";
      this.flashTarget.hidden = false;
    }
  }

  validate() {
    let msg = []
    let quill = Quill.find(this.quillTarget)
    if(this.noteTarget.value == "" || this.noteTarget.value =="<p><br></p>" || quill.container.firstChild.textContent.trim() === '') {
      msg.push("Please input note content.");
    }

    return msg;
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}