import { Controller } from "@hotwired/stimulus"
import { CountUp } from 'countup.js';
import Chart from 'chart.js/auto';

export default class extends Controller {

  static targets = [ "chartpie", "statelist", 'box0', 'box1', 'box2', 'box3', 'count0', 'count1', 'count2', 'count3' ]
  static values = { labels: Array, counts: Array, labels1: Array, counts1: Array, labels2: Array, counts2: Array, labels3: Array, counts3: Array, 
  total: Number, total1: Number, total2: Number, total3: Number, namespace: String }

  connect() {

    if(this.hasBox0Target) {
      this.box0Target.classList.add("active");
    }

    if(this.hasCount0Target) {
      const countUp = new CountUp('count0', this.count0Target.getAttribute("countTo"));
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    }

    if(this.hasCount1Target) {
      const countUp = new CountUp('count1', this.count1Target.getAttribute("countTo"));
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    }

    if(this.hasCount2Target) {
      const countUp = new CountUp('count2', this.count2Target.getAttribute("countTo"));
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    }

    if(this.hasCount3Target) {
      const countUp = new CountUp('count3', this.count3Target.getAttribute("countTo"));
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    }   

    if(this.hasStatelistTarget){
      this.statelistTarget.innerHTML = this.generateList(this.namespaceValue, this.labelsValue, this.countsValue, this.totalValue);
    }

    if(this.hasChartpieTarget){
      console.log(this.labelsValue);
      console.log(this.countsValue);

      this.charpie = this.showChartPie(this.chartpieTarget, "All Orders", this.labelsValue, this.countsValue);
    }
  }

  generateList(namespace, labels, values, total) {
    let result = ``;
    labels.forEach((label, index) => {
      result += `<li class="list-group-item px-0 border-0">
        <div class="row align-items-center">
          <div class="col">
            <p class="text-xs font-weight-bold mb-0">State:</p>
            <h6 class="text-sm mb-0"><a href="/${namespace}/orders_by_state${this.get_search(label)}" target="_blank">${label}</a></h6>
          </div>
          <div class="col text-center">
            <p class="text-xs font-weight-bold mb-0">Count:</p>
            <h6 class="text-sm mb-0">${values[index]}</h6>
          </div>
          <div class="col text-center">
            <p class="text-xs font-weight-bold mb-0">Percentage:</p>
            <h6 class="text-sm mb-0">${(100 * values[index] / total).toFixed(2)}%</h6>
          </div>
        </div>
        <hr class="horizontal dark mt-3 mb-1">
      </li>`;
    });
    return result;
  }

  get_search(label) {
    let search = window.location.search;
    return search ? search + "&state=" + label : "?state=" + label;
  }

  showChartPie(el, label, labels, counts) {
    // Pie chart
    return new Chart(el, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [{
          label: label,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: ['#17c1e8', '#00ffbc', '#3A416F', '#ffe000', '#a2ff00', '#5e72e4', '#a8b8d8', '#ff004f'],
          data: counts,
          fill: false
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          }
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false
            }
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            }
          },
        },
      },
    });
 
  }

  showAll() {
    this.statelistTarget.innerHTML = this.generateList(this.namespaceValue, this.labelsValue, this.countsValue, this.totalValue);
    this.charpie.destroy();
    this.charpie = this.showChartPie(this.chartpieTarget, "All Orders", this.labelsValue, this.countsValue);
    this.setBoxActive(0);
  }

  showInProgress() {
    this.statelistTarget.innerHTML = this.generateList(this.namespaceValue, this.labels1Value, this.counts1Value, this.total1Value);
    this.charpie.destroy();
    this.charpie = this.showChartPie(this.chartpieTarget, "In Progress Orders", this.labels1Value, this.counts1Value);
    this.setBoxActive(1);
  }

  showFinished() {
    this.statelistTarget.innerHTML = this.generateList(this.namespaceValue, this.labels2Value, this.counts2Value, this.total2Value);
    this.charpie.destroy();
    this.charpie = this.showChartPie(this.chartpieTarget, "Delivered, Cancelled, Refunded Orders", this.labels2Value, this.counts2Value);
    this.setBoxActive(2);
  }

  showProblem() {
    this.statelistTarget.innerHTML = this.generateList(this.namespaceValue, this.labels3Value, this.counts3Value, this.total3Value);
    this.charpie.destroy();
    this.charpie = this.showChartPie(this.chartpieTarget, "Problem Orders", this.labels3Value, this.counts3Value);
    this.setBoxActive(3);
  }

  setBoxActive(box) {
    box == 0 ? this.box0Target.classList.add("active") : this.box0Target.classList.remove("active");
    box == 1 ? this.box1Target.classList.add("active") : this.box1Target.classList.remove("active");
    box == 2 ? this.box2Target.classList.add("active") : this.box2Target.classList.remove("active");
    box == 3 ? this.box3Target.classList.add("active") : this.box3Target.classList.remove("active");
  }

}