import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ ]

  connect() {
    
  }

  closeFlash(event) {
    event.target.closest('.alert').hidden = true;
  }
}