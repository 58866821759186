import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "option" ]

  select_option(e) {
    const url = new URL(window.location.href);
    url.searchParams.set('per_page', e.target.value);
    window.location.href = url.toString();
  }
}