import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "modal", "content", "valuesContainer", "values" ]
  static values = {
    count: Number
  }

  initialize() {
    this.valueInputCount = this.countValue;
  }

  connect() {

  }

  appendValueInput(event) {
    let filled = this.valuesTargets.filter(d => d.value != "").length
    if( filled == this.valueInputCount ) {
      const item = document.createElement('input')
      item.setAttribute('name', 'option_values[]')
      item.setAttribute('class', 'form-control')
      item.setAttribute(`data-${this.identifier}-target`, 'values')
      item.setAttribute('data-action', `input->${this.identifier}#appendValueInput`)
      
      this.valuesContainerTarget.append(item);
      this.valueInputCount += 1;
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}