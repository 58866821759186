import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";

export default class extends Controller {

  static targets = [ "modal", "content", "state", "country" ]

  connect() {
    if(this.hasStateTarget){
      new Choices(this.stateTarget, {
        searchEnabled: true,
        itemSelectText: ""
      });
    }

    if(this.hasCountryTarget){
      new Choices(this.countryTarget, {
        searchEnabled: true,
        itemSelectText: ""
      });
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}