import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "./helpers"
import { DataTable } from "simple-datatables";

export default class extends Controller {

  static targets = [ "datatable", "row", "gray" ]

  connect() {
    new DataTable(this.datatableTarget, {
        searchable: false,
        fixedHeight: false,
        perPageSelect: false,
        paging: false,
        info: false,
        iDisplayLength: 50
    });
    
  }

  toggleGray(event) {
    const rowId = event.target.dataset.rowId
    const row = this.rowTargets.find(target => target.dataset.id == rowId)
    if(event.target.checked) {
      row.classList.add("bg-gray-400")
    } else {
      row.classList.remove("bg-gray-400")
    }

    fetch(event.target.dataset.markGrayUrl, {
      method: "PUT",
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': getMetaValue("csrf-token") },
      body: JSON.stringify({ is_gray: event.target.checked }),    
    }).then (response => response.json())
    .then(data => {
        if(data.status == 'bad_request') {
          alert(data.message);
          row.classList.remove("bg-gray-400")
          event.target.checked = !event.target.checked
        }
      }
    );
  }
}