import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js";
import flatpickr from "flatpickr";

export default class extends Controller {

  static targets = [ "datepickerfrom", "shippingCategories", "vendors" ]

  connect() {

    new Choices(this.shippingCategoriesTarget);
    
    new Choices(this.vendorsTarget);

    flatpickr(this.datepickerfromTarget, {
      allowInput: true,
      dateFormat: "Y/m/d"
    });
  }
}