import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "modal", "content", "form", "flash", "reason", "note" ]

  connect() {
    this.flashTarget.hidden = true;
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  submit(e) {
    let msg = this.validate()
    if(msg.length > 0) {
      this.flashTarget.innerHTML = "<div class='p-2'>" + msg.join('<br />') + "</div>";
      this.flashTarget.hidden = false;
    } else {
      this.formTarget.submit()
    }
  }

  validate() {
    let msg = []

    if(this.reasonTarget.value == "") {
      msg.push("Please choose a cancel reason.")
    }

    if(this.noteTarget.getAttribute('data-fulfilled') == 'false' && this.noteTarget.value.trim().length == 0) {
      msg.push("Please input cancel note.")
    }

    if(this.noteTarget.getAttribute('data-fulfilled') == 'true' && this.noteTarget.value.trim().length < 30) {
      msg.push("Please input more than 30 characters cancel note.")
    }

    return msg;
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}