import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "./helpers"
import { Dropzone } from "dropzone";
import Sortable from 'sortablejs';

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = [ "images", "previews" ]
  static values = {
    url: String,
    updatePositionUrl: String,
    variantId: Number,
    maxFile: { type: Number, default: 10 },
    maxFileSize: { type: Number, default: 256 },
    acceptedFiles: { type: Array, default: ['image/png', 'image/jpeg', 'image/jpg'] },
    addRemoveLinks: { type: Boolean, default: true},
  }

  connect() {
    this.dropZone = createDropZone(this)
    this.bindEvents()
    this.displayExistingImages()
    this.sortable()
  }

  displayExistingImages() {
    for(let image of this.imagesTargets) {
      let mockFile = { name: image.dataset.name, size: image.dataset.size, image_url: image.dataset.imageUrl, image_id: image.dataset.id };
      this.dropZone.displayExistingFile(mockFile, image.dataset.url);
    }
  }

  sortable() {
    let sortable_el = this.dropZone.previewsContainer;
    Sortable.create(sortable_el, {
        handle: '.move-handle',
        animation: 550,
        ghostClass: 'bg-light',
        dragClass: "sortable-drag-v",
        easing: 'cubic-bezier(1, 0, 0, 1)',
        swapThreshold: 0.9,
        forceFallback: true,
        onEnd: function (evt) {
          let itemEl = evt.item
          let positions = { }

          let previews = sortable_el.querySelectorAll(".dz-preview")
          let position = 1
          for(let preview of previews) {
            let image_id = preview.dataset.imageId.toString()
            positions[image_id] = position
            position += 1
          }

          // console.log(sortable_el.dataset.updatePositionUrl)

          fetch(sortable_el.dataset.updatePositionUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': getMetaValue("csrf-token") },
            body: JSON.stringify({positions: positions})
          })
        }
    });
  }

  bindEvents() {
    this.dropZone.on("addedfile", function(file) {
      if(file.image_id) {
        file.previewElement.setAttribute('data-image-id', file.image_id);
        // let editLink = Dropzone.createElement(
        //   `<a class="dz-edit" href="${file.image_url}/edit" data-dz-edit>Edit</a>`
        // );
        // file.previewElement.appendChild(editLink);
      }
    });

    this.dropZone.on("sending", function(file, xhr, formData) {
      formData.append("image[viewable_id]", this.element.dataset.dropzoneVariantIdValue);
      formData.append("image[file_size]", file.size);
    });

    this.dropZone.on("success", function(file, response, e) {
      if(response.image_id) {
        file.image_url = this.element.dataset.dropzoneUrlValue + "/" + response.image_id.toString();
        file.previewElement.setAttribute('data-image-id', response.image_id);
        // let editLink = Dropzone.createElement(
        //   `<a class="dz-edit" href="${file.image_url}/edit" data-dz-edit>Edit</a>`
        // );
        // file.previewElement.appendChild(editLink);
      }
    });

    this.dropZone.on("removedfile", (file) => {
      if(file.image_url) {
        fetch(file.image_url, {
          method: "DELETE",
          headers: { 'X-CSRF-Token': getMetaValue("csrf-token") }
        });
      }
    })
  }

  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.urlValue,
    headers: controller.headers,
    maxFiles: controller.maxFilesValue,
    maxFilesize: controller.maxFileSizeValue,
    acceptedFiles: controller.acceptedFilesValue.join(','),
    addRemoveLinks: controller.addRemoveLinksValue,
    paramName: "image[attachment]",
    autoProcessQueue: true,
    dictDefaultMessage: 'Drop the image here or click to upload.',
    dictRemoveFile: 'Remove image',
    dictRemoveFileConfirmation: "Do you really want to remove this image?",
    previewsContainer: ".dropzone-previews",
    previewTemplate: document.getElementById('preview-template').innerHTML
  })
}