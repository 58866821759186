import { Controller } from "@hotwired/stimulus"
import Quill from "quill"

export default class extends Controller {

  static targets = [ "quill", "note", "form" ]

  connect() {
    if(!Quill.find(this.quillTarget)) {
      let quill = new Quill(this.quillTarget, {
        theme: 'snow'
      });
      let note = this.noteTarget;
      quill.on('text-change', function(delta, oldDelta, source) {
        note.value = quill.container.firstChild.innerHTML;
      });
      this.quill = quill
    }
  }

  isQuillEmpty(){
    return this.quill.getContents().ops[0].insert == '\n' && this.quill.getLength() < 2;
  }

  validate(event) {
    if(this.isQuillEmpty()) {
      event.detail.formSubmission.stop();
    }
  }

  reset(event) {
    this.noteTarget.value = "";
    this.quill.setText('');
  }

  close(event) {
    const csrf_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    // console.log(event.target.dataset.url)
    fetch(event.target.dataset.url, {
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf_token },
      body: JSON.stringify({ order_request: { finalized_note: this.noteTarget.value } }),
    }).then (response => response.text())
    .then(html => {
        Turbo.renderStreamMessage(html);
        this.formTarget.hidden = true;
      }
    );

  }
}