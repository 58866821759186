import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "price", "costPrice", "profit", "backorderable" ]

  connect() {
    this.calprofit();
  }

  calprofit() {
    let price = this.priceTarget.value;
    let cost_price = this.costPriceTarget.value;
    if(price && cost_price) {
      let profit = (price - cost_price).toFixed(2);
      profit = profit < 0 ? 0 : profit;
      this.profitTarget.value = profit;
    }
  }

  toggleBackorderable(event) {
    if(event.target.dataset.url) {
      const csrf_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
      fetch(event.target.dataset.url, {
        method: "PUT",
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf_token },
        body: JSON.stringify({ backorderable: event.target.checked }),
      });
    }
  }
}