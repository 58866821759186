import { Controller } from "@hotwired/stimulus"
import Quill from "quill"
import Choices from "choices.js";
import flatpickr from "flatpickr";

export default class extends Controller {

  static targets = [ "quill", "description", "squill", "shortDescription", "taxons", "tags", "price", "costPrice", "profit", "datepickerfrom", "datepickerto", "shippingCategories", "location", "vendors", "locations", "backorderable" ]

  connect() {
    this.calprofit();

    if(!Quill.find(this.quillTarget)) {
      let quill = new Quill(this.quillTarget, {
        theme: 'snow'
      });
      let description = this.descriptionTarget;
      quill.on('text-change', function(delta, oldDelta, source) {
        description.value = quill.container.firstChild.innerHTML;
      });
      this.quill = quill
    }

    if(!Quill.find(this.squillTarget)) {
      let squill = new Quill(this.squillTarget, {
        theme: 'snow'
      });
      let short_description = this.shortDescriptionTarget;
      squill.on('text-change', function(delta, oldDelta, source) {
        short_description.value = squill.container.firstChild.innerHTML;
      });
      this.squill = squill
    }

    new Choices(this.shippingCategoriesTarget);
    
    new Choices(this.taxonsTarget);

    new Choices(this.tagsTarget);

    new Choices(this.vendorsTarget);

    flatpickr(this.datepickerfromTarget, {
      allowInput: true,
      dateFormat: "Y/m/d",
      maxDate: this.datepickerfromTarget.dataset.maxdate
    });

    flatpickr(this.datepickertoTarget, {
      allowInput: true,
      dateFormat: "Y/m/d",
      minDate: this.datepickertoTarget.dataset.mindate
    });
  }

  calprofit() {
    let price = this.priceTarget.value;
    let cost_price = this.costPriceTarget.value;
    if(price && cost_price) {
      let profit = (price - cost_price).toFixed(2);
      profit = profit < 0 ? 0 : profit;
      this.profitTarget.value = profit;
    }
  }

  setLocation(event) {
    const url = event.target.dataset.url
    this.locationTarget.innerHTML = event.target.innerHTML
    const csrf_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    fetch(event.target.dataset.url, {
        method: "GET",
        headers: { 'Accept': "text/vnd.turbo-stream.html", 'X-CSRF-Token': csrf_token },
      }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }

  toggleBackorderable(event) {
    if(event.target.dataset.url) {
      const csrf_token = document.querySelector('meta[name=csrf-token]').getAttribute('content');
      fetch(event.target.dataset.url, {
        method: "PUT",
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf_token },
        body: JSON.stringify({ backorderable: event.target.checked }),
      });
    }
  }
}