import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "notes", "arrows" ]

  connect() {

  }

  toggle(event) {
    const noteId = event.params.id;
    const note = this.notesTargets.find(target => target.dataset.id == noteId)
    note.classList.toggle("show")

    const arrow = this.arrowsTargets.find(target => target.dataset.id == noteId)
    if (arrow.classList.contains("ni-bold-right")) {
      arrow.classList.replace("ni-bold-right", "ni-bold-down");
    } else {
      arrow.classList.replace("ni-bold-down", "ni-bold-right");
    }
  }

}