import { Controller } from "@hotwired/stimulus"
import moment from 'moment';
import flatpickr from "flatpickr";
import umbFlatpickrPlugins from '~/javascripts/plugins/predefined-ranges'

export default class extends Controller {

  static targets = [ "datetimepicker" ]
  static values = { fromdate: String, todate: String }

  connect() {
    if(this.hasDatetimepickerTarget){
      let url = new URL(window.location.href);
      let default_from_date = this.fromdateValue ? moment(this.fromdateValue) : moment();
      let default_to_date = this.todateValue ? moment(this.todateValue) : moment();
      let start = url.searchParams.get('from_date') ? url.searchParams.get('from_date') : default_from_date.toDate();
      let end = url.searchParams.get('to_date') ? url.searchParams.get('to_date') : default_to_date.toDate();

      flatpickr(this.datetimepickerTarget, {
        mode: 'range',
        defaultDate: [start, end],
        plugins: umbFlatpickrPlugins,
        ranges: {
            'Today': [new Date(), new Date()],
            'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
            'Last 7 Days': [moment().subtract(6, 'days').toDate(), new Date()],
            'Last 30 Days': [moment().subtract(29, 'days').toDate(), new Date()],
            'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate()
            ]
        },
        rangesOnly: true, // only show the ranges menu unless the custom range button is selected
        rangesAllowCustom: true, // adds a Custom Range button to show the calendar
        rangesCustomLabel: 'Custom Range', // customize the label for the custom range button
        onClose: function(selectedDates, dateStr, instance) {
          let from_date = moment(selectedDates[0]).format('YYYY-MM-DD');
          let to_date = moment(selectedDates[1]).format('YYYY-MM-DD');
          let is_default_range_chosen = (from_date == default_from_date.format('YYYY-MM-DD')) && (to_date == default_to_date.format('YYYY-MM-DD'))

          let url_from_date = url.searchParams.get('from_date');
          let url_to_date = url.searchParams.get('to_date');
          let url_date_empty = !url_from_date && !url_to_date
          
          if(!(is_default_range_chosen && url_date_empty)) {
            if(from_date != url_from_date || to_date != url_to_date) {
              url.searchParams.set('from_date', from_date);
              url.searchParams.set('to_date', to_date);
              window.location = url.href;
            }
          }
        }
      });

    }
  }

}