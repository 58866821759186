import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {

  static targets = [ "datetimepicker" ]

  connect() {
    if(this.hasDatetimepickerTarget){

      flatpickr(this.datetimepickerTarget, {
        allowInput: true
      });
    }
  }

}