import { Controller } from "@hotwired/stimulus"
import Quill from "quill"

export default class extends Controller {

  static targets = [ "modal", "content", "carrier", "hide", "quill", "note" ]

  connect() {
    if(!Quill.find(this.quillTarget)) {
      let quill = new Quill(this.quillTarget, {
        theme: 'snow'
      });
      let note = this.noteTarget;
      if(note.value) {
        quill.container.firstChild.innerHTML = note.value;
      }
      quill.on('text-change', function(delta, oldDelta, source) {
        note.value = quill.container.firstChild.innerHTML;
      });
    }

    if(this.hideTarget.value) {
      this.hideTarget.hidden = false;
      this.hideTarget.required = true;
    } else {
      this.hideTarget.hidden = true;
      this.hideTarget.required = false;
    }
  }

  toggleCarrier(event) {
    if(event.target.value == "Other") {
      this.hideTarget.hidden = false;
      this.hideTarget.required = true;
    } else {
      this.hideTarget.hidden = true;
      this.hideTarget.required = false;
      this.hideTarget.value = "";
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}