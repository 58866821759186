import { Controller } from "@hotwired/stimulus"
import Quill from "quill"

export default class extends Controller {

  static targets = [ "modal", "content", "stc1", "stc2", "quantity", 'cost', "carrier", "other", "forwardCarrier", "forwardOther", "flash", "quill", "note", "stwfields" ]

  connect() {
    this.flashTarget.hidden = true;
    this.otherTarget.hidden = true;
    this.otherTarget.required = false;

    this.forwardOtherTarget.hidden = true;
    this.forwardOtherTarget.required = false;

    if(!Quill.find(this.quillTarget)) {
      let quill = new Quill(this.quillTarget, {
        theme: 'snow'
      });
      let note = this.noteTarget;
      quill.on('text-change', function(delta, oldDelta, source) {
        note.value = quill.container.firstChild.innerHTML;
      });
    }
  }

  toggleStc(event) {
    if(event.target.checked) {
      if(event.target.getAttribute('data-add-fulfill-modal-target') == "stc1") {
        this.stc2Target.checked = false;
        this.stwfieldsTarget.hidden = true;
      } else {
        this.stc1Target.checked = false;
        this.stwfieldsTarget.hidden = false;
      }
    }
  }

  toggleCarrier(event) {
    if(event.target.value == "Other") {
      this.otherTarget.hidden = false;
      this.otherTarget.required = true;
    } else {
      this.otherTarget.hidden = true;
      this.otherTarget.required = false;
    }
  }

  toggleForwardCarrier(event) {
    if(event.target.value == "Other") {
      this.forwardOtherTarget.hidden = false;
      this.forwardOtherTarget.required = true;
    } else {
      this.forwardOtherTarget.hidden = true;
      this.forwardOtherTarget.required = false;
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // validate modal on form submission
  // action: "turbo:submit-start->turbo-modal#submitStart"
  submitStart(e) {
    let msg = this.validate()
    if(msg.length > 0) {
      e.detail.formSubmission.stop()
      this.flashTarget.innerHTML = "<div class='p-2'>" + msg.join('<br />') + "</div>";
      this.flashTarget.hidden = false;
    }
  }

  validate() {
    let msg = []
    if(!(this.stc1Target.checked || this.stc2Target.checked)) {
      msg.push("Please choose an order destination.");
    }

    if(!this.quantityTarget.value) {
      msg.push("Please input quantity field.");
    }

    if(!this.costTarget.value) {
      msg.push("Please input cost field.");
    }

    if(this.quantityTarget.value <= 0) {
      msg.push("Order quantity should big than 0");
    }

    if(this.costTarget.value <= 0) {
      msg.push("Order cost should big than 0");
    }

    if(this.carrierTarget.value == "Other" && !this.otherTarget.value) {
      msg.push("Please input other carrier name")
    }

    if(this.forwardCarrierTarget.value == "Other" && !this.forwardOtherTarget.value) {
      msg.push("Please input other forward carrier name")
    }

    return msg;
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}