import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "modal", "content", "flash", "step1", "step2", "reason", "note", "stockItem", "itemPrice", "itemQuantity", "itemCount", "itemsSubtotal",
    "taxSubtotal", "shipmentSubtotal", "promoSubtotal", "suggestedAmount", "creditAllowed", "refundAmount", "next", "submit" ]
  
  static values = {
    order: Object
  }

  connect() {
    this.step2Target.hidden = true;
    this.submitTarget.hidden = true;
    this.flashTarget.hidden = true;
  }

  next() {
    if(this.reasonTarget.value && this.noteTarget.value.length >= 30) {
      this.flashTarget.innerHTML = "";
      this.flashTarget.hidden = true;
      this.step1Target.hidden = true;
      this.step2Target.hidden = false;
      this.nextTarget.hidden = true;
      this.submitTarget.hidden = false;
    } else {
      let msg = []
      if(this.reasonTarget.value == "") {
        msg.push("Please choose a refund reason.");
      }
      if(this.noteTarget.value.length < 30) {
        msg.push("Please input more than 30 characters refund note.");
      }
      this.flashTarget.innerHTML = "<div class='p-2'>" + msg.join('<br />') + "</div>";
      this.flashTarget.hidden = false;
    }
  }

  calculate(event) {
    const promotion = this.orderValue.promo_total;
    const shipment = this.orderValue.shipment_total;
    const item_total = this.orderValue.item_total;
    const tax_total = this.orderValue.additional_tax_total;
    const credit_allowed = this.creditAllowedTarget.dataset.creditAllowed;

    let suggested_amount = 0
    let quantity = event.target.value;
    let items_subtotal = 0;
    let promo_subtotal = 0;
    let tax_subtotal = 0;
    let item_count = 0;
    let item_part = 0;
    let shipment_subtotal = 0;

    for (let i in this.stockItemTargets) {
      let price = parseFloat(this.itemPriceTargets[i].dataset.amount);
      let refunded_quantity = parseInt(this.itemQuantityTargets[i].value);

      item_part += refunded_quantity *  price;
      item_count += refunded_quantity;
      items_subtotal += price * refunded_quantity;
    }
    const ratio = item_part / item_total;
    promo_subtotal = ratio * promotion;
    tax_subtotal = ratio * tax_total;
    shipment_subtotal = ratio * shipment;
    suggested_amount = ratio * credit_allowed;

    this.refundAmountTarget.value = suggested_amount.toFixed(2);
    this.itemCountTarget.innerHTML = item_count;
    this.itemsSubtotalTarget.innerHTML = items_subtotal.toFixed(2);
    this.taxSubtotalTarget.innerHTML = tax_subtotal.toFixed(2);
    this.promoSubtotalTarget.innerHTML = promo_subtotal.toFixed(2);
    this.shipmentSubtotalTarget.innerHTML = shipment_subtotal.toFixed(2);
    this.suggestedAmountTarget.innerHTML = suggested_amount.toFixed(2);
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.modalTarget.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    console.log(e)
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.contentTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}